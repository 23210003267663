import BaseCollection from "./BaseCollection";
import CustomerRankTypeModel from "../models/CustomerRankTypeModel";
import {
  CustomerRankTypeCollectionJson,
  CustomerRankTypeJson,
} from "@/types/Customer.type";

class CustomerRankTypeCollection extends BaseCollection<
  CustomerRankTypeJson,
  CustomerRankTypeModel,
  CustomerRankTypeCollectionJson
> {
  itemsFromJson(jsonItems: CustomerRankTypeJson[]): CustomerRankTypeModel[] {
    return jsonItems.map((item) => new CustomerRankTypeModel(item));
  }
}

export default CustomerRankTypeCollection;
