import { useQuery } from "@tanstack/react-query";
import { getCustomer } from "../api/account/customer";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";

export default function useCustomer() {
  const { data, status } = useSession();
  const [isLoading, setIsLoading] = useState(true);

  const query = useQuery({
    queryKey: ["customer"],
    queryFn: async () => {
      try {
        setIsLoading(true);

        const id = data?.user.company.customer.id;
        if (id) {
          const { data: cus } = await getCustomer(id);
          return cus;
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    staleTime: 1000 * 60,
    enabled: status === "authenticated",
    retry: 3,
    
  });

  useEffect(() => {
    if (query.isSuccess || query.isError || status === "unauthenticated") {
      setIsLoading(false);
    }
  }, [query.isLoading , status]);

  return {
    ...query,
    isLoading: isLoading,
  };
}
