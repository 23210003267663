import BaseModel from "./BaseModel";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { CustomerEventJson } from "@/types/Customer.type";

class CustomerEventModel
  extends BaseModel
  implements BaseModelJson<CustomerEventJson>
{
  source_id: number;
  source_type: number;
  event_type: number;
  event_detail_type: number;
  rank_old: number;
  rank_new: number;
  status: number;
  date_created: number;

  constructor(json: CustomerEventJson) {
    super();

    this.source_id = json.source_id || 0;
    this.source_type = json.source_type || 0;
    this.event_type = json.event_type || 0;
    this.event_detail_type = json.event_detail_type || 0;
    this.rank_old = json.rank_old || 0;
    this.rank_new = json.rank_new || 0;
    this.status = json.status || 0;
    this.date_created = json.date_created || 0;
  }

  static getDefaultData(): CustomerEventJson {
    return {
      source_id: 0,
      source_type: 0,
      event_type: 0,
      event_detail_type: 0,
      rank_old: 0,
      rank_new: 0,
      status: 0,
      date_created: 0,
    };
  }

  toJson(): CustomerEventJson {
    return {
      source_id: this.source_id,
      source_type: this.source_type,
      event_type: this.event_type,
      event_detail_type: this.event_detail_type,
      rank_old: this.rank_old,
      rank_new: this.rank_new,
      status: this.status,
      date_created: this.date_created,
    };
  }
}

export default CustomerEventModel;
