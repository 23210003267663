import { CustomerRankTypeJson } from "@/types/Customer.type";
import { BaseModelJson } from "../types/BaseModelJson.type";
import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class CustomerRankTypeModel
  extends BaseModel
  implements BaseModelJson<CustomerRankTypeJson>
{
  datecreated: number;
  datedeleted: number;
  datemodified: number;
  description: string;
  id: number;
  isdeleted: number;
  max: number;
  min: number;
  status: number;
  title: string;

  constructor(json: CustomerRankTypeJson) {
    super();

    this.datecreated = json.datecreated || 0;
    this.datedeleted = json.datedeleted || 0;
    this.datemodified = json.datemodified || 0;
    this.description = json.description || "";
    this.id = json.id || 0;
    this.isdeleted = json.isdeleted || 0;
    this.max = json.max || 0;
    this.min = json.min || 0;
    this.status = json.status || 0;
    this.title = json.title || "";
  }

  static getDefaultData(): CustomerRankTypeJson {
    return {
      datecreated: 0,
      datedeleted: 0,
      datemodified: 0,
      description: "",
      id: 0,
      isdeleted: 0,
      max: 0,
      min: 0,
      status: 0,
      title: "",
    };
  }

  toJson(): CustomerRankTypeJson {
    return {
      datecreated: this.datecreated,
      datedeleted: this.datedeleted,
      datemodified: this.datemodified,
      description: this.description,
      id: this.id,
      isdeleted: this.isdeleted,
      max: this.max,
      min: this.min,
      status: this.status,
      title: this.title,
    };
  }
}

export default CustomerRankTypeModel;
