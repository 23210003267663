"use client";

import { useAppSelector } from "@/redux/hook";

const useDeviceSize = () => {
	const device = useAppSelector((state) => state.ShopReducer.device);
	// const [mouted, setMouted] = useState(false);
	return {
		isMobile: device === "mobile",
	};
};

export default useDeviceSize;
