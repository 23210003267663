"use client";

import { useQuery } from "@tanstack/react-query";
import { getTimeServer } from "../api/shop/setting";
import SettingApi from "@/common/constants/setting";

export default function useTimeServer(saleTime?: number) {
  return useQuery({
    queryKey: [SettingApi.KEY.k_time_server],
    queryFn: () => {
      return getTimeServer(SettingApi.KEY.k_time_server);
    },
    staleTime: saleTime ?? 60 * 1000,
  });
}
