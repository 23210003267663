"use client";

import React, { useState } from "react";
import NextImage from "next/image";
import { OnLoadingComplete } from "next/dist/shared/lib/get-img-props";
import { cn } from "@/utils/utils";
import { ProductImage2 } from "@/types/Product.type";
import imageDefault from "public/assets/images/imageDefault.png";
import PopupV2 from "../PopupV2";
export type PropsImage = {
	src: string;
	alt: string;
	width?: number | `${number}`;
	height?: number | `${number}`;
	layout?: string;
	className?: string;
	loader?: any;
	fill?: boolean;
	sizes?: string;
	quality?: number;
	priority?: boolean;
	placeholder?: "blur" | "empty";
	style?: React.CSSProperties;
	onLoadingComplete?: OnLoadingComplete | undefined;
	onLoad?: any;
	onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
	loading?: "eager" | "lazy";
	blurDataURL?: string;
	objectFit?: string | undefined;
	objectPosition?: string | undefined;
	lazyBoundary?: string | undefined;
	lazyRoot?: string | undefined;
	popupImage?: boolean;
	imgs?: ProductImage2[];
};

const Image = ({
	src,
	alt,
	width,
	height,
	layout,
	className,
	loader,
	fill,
	sizes,
	quality,
	priority,
	placeholder,
	style,
	onLoadingComplete,
	onLoad,
	onError,
	loading,
	blurDataURL,
	objectFit,
	objectPosition,
	lazyBoundary,
	lazyRoot,
	popupImage,
	imgs,
}: PropsImage) => {
	const defaultImage = imageDefault;
	const srcImage =
		src?.length > 0
			? src.includes("https") || src.includes("http")|| src.includes("/assets")
				? src
				: process.env.REACT_APP_ASSET_URL + "/uploads/filecloud/" + src
			: defaultImage;

	const isErrorImage = !src || src?.length <= 0;
	const [isOpenImage, setIsOpenImage] = useState(false);
	const [isError, setIsError] = useState(false);

	return (
		<>
			{width || height ? (
				<NextImage
					layout={layout}
					src={isError ? defaultImage : srcImage}
					alt={alt}
					width={width}
					height={height}
					loader={loader}
					className={cn(
						{
							"cursor-pointer": popupImage,
						},
						{ "bg-gray-200": isErrorImage || isError },
						className,
						{
							"object-contain  flex items-center justify-center":
								isErrorImage || isError,
						}
					)}
					style={style}
					fill={fill}
					sizes={sizes}
					quality={quality}
					priority={priority}
					placeholder={placeholder}
					onLoadingComplete={onLoadingComplete}
					onLoad={onLoad}
					onError={(event) => {
						onError && onError(event);
						setIsError(true);
					}}
					loading={loading}
					blurDataURL={blurDataURL}
					objectFit={objectFit}
					objectPosition={objectPosition}
					lazyBoundary={lazyBoundary}
					lazyRoot={lazyRoot}
					onClick={(e) => {
						if (popupImage) {
							e.stopPropagation();
							setIsOpenImage(true);
						}
					}}
				/>
			) : (
				<NextImage
					src={srcImage}
					alt={alt}
					layout={layout ? layout : "fill"}
					loader={loader}
					fill={fill}
					sizes={sizes}
					quality={quality}
					priority={priority}
					placeholder={placeholder}
					className={cn(
						{
							"cursor-pointer": popupImage,
						},
						{ "bg-gray-200": isErrorImage || isError },
						className,
						{
							"object-contain  flex items-center justify-center":
								isErrorImage || isError,
						}
					)}
					style={style}
					onLoadingComplete={onLoadingComplete}
					onLoad={onLoad}
					onError={onError}
					loading={loading}
					blurDataURL={blurDataURL}
					objectFit={objectFit}
					objectPosition={objectPosition}
					lazyBoundary={lazyBoundary}
					lazyRoot={lazyRoot}
					onClick={(e) => {
						if (popupImage) {
							e.stopPropagation();
							setIsOpenImage(true);
						}
					}}
				/>
			)}
			{popupImage && (
				<PopupV2
					onOpenChange={() => setIsOpenImage(false)}
					open={isOpenImage}
					animate="fade">
					{imgs ? (
						<>
							{/* <Slider
					dataSource={imgs}
					initialSlide={0}
					isloading={false}
					speed={800}
					itemRender={}
					navigation={false}
				
					autoplay={false}
					slidesPerGroup={1}
					slidesPerView={1}

					responsive={{
						xxs: { slidesPerView: 1, spaceBetween: 0 },
						xs: { slidesPerView: 1, spaceBetween: 0 },
						sm: { slidesPerView: 1, spaceBetween: 0 },
						md: { slidesPerView: 1, spaceBetween: 0 },
						lg: { slidesPerView: 1, spaceBetween: 0 },
						xl: { slidesPerView: 1, spaceBetween: 0 },
					}}
				/> */}
						</>
					) : (
						<NextImage
							width={500}
							height={500}
							src={srcImage}
							alt={alt}
							loader={loader}
							className={className}
							fill={fill}
							sizes={sizes}
							quality={quality}
							priority={priority}
							placeholder={placeholder}
							style={style}
							onLoadingComplete={onLoadingComplete}
							onLoad={onLoad}
							onError={onError}
							loading={loading}
							blurDataURL={blurDataURL}
							objectFit={objectFit}
							objectPosition={objectPosition}
							lazyBoundary={lazyBoundary}
							lazyRoot={lazyRoot}
						/>
					)}
				</PopupV2>
			)}
		</>
	);
};

export default Image;
